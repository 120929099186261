
<template>
  <div class="templateClass">
    <div class="templateCentreContent">
      <el-card class="box-card">
        <div slot="header" style="position: relative">
          <div class="query">
            <el-input
              style="width: 250px"
              clearable
              placeholder="请输入昵称/手机号/邮箱"
              v-model="queryData.keyWord"
              @keyup.enter.native="getDataList(true)"
            ></el-input>

            <el-button type="success" class="ml10" @click="getDataList(true)"
              >查询</el-button
            >

            <el-button type="primary" @click="addOrEditPopup()"
              >新增用户</el-button
            >
          </div>
        </div>

        <el-table
          :data="listData"
          v-loading="loading"
          element-loading-text="正在拼命加载中"
          element-loading-spinner="el-icon-loading"
          border
          stripe
        >
          <el-table-column
            type="index"
            :index="1"
            label="#"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="roleName"
            label="所属角色"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="nickName"
            label="用户昵称"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="username"
            label="登录名称"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="mobile"
            label="手机号"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="email"
            label="邮箱"
            min-width="200"
          ></el-table-column>
          <el-table-column prop="status" label="状态" min-width="50">
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.status == 1 ? 'color:#67C23A' : 'color:#F56C6C'
                "
                >{{ scope.row.status == 1 ? "启用" : "禁用" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="创建时间" min-width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | dateFormat1 }}</p>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            prop="operation"
            label="操作"
            width="295px;"
          >
            <template slot-scope="scope">
              <div class="tableOperation">
                <el-button type="text" @click="addOrEditPopup(scope.row.userId)"
                  >编辑</el-button
                >
                <span class="operationLine">|</span>
                <el-button
                  class="F56C6C"
                  type="text"
                  @click="del(scope.row.userId)"
                  >删除</el-button
                >
                <span class="operationLine">|</span>
                <el-button
                  class="E6A23C"
                  type="text"
                  @click="
                    assignRolesPopup(scope.row.userId, scope.row.sysRoleList)
                  "
                  >分配角色</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-pagination
          class="floatRight"
          v-if="total > 10"
          @size-change="getDataListSizeChange"
          @current-change="getDataListCurrentChange"
          :current-page.sync="queryData.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryData.size"
          :total="total"
          layout="total,sizes, prev, pager, next"
        ></el-pagination>
      </el-card>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-edit ref="addOrEdit" @refreshDataList="getDataList()"></add-or-edit>
    <!-- 分配角色 -->
    <el-dialog
      title="分配角色"
      :close-on-click-modal="false"
      :visible.sync="assignRolesVisible"
      center
      width="400px"
    >
      <el-form
        ref="assignRolesFrom"
        :rules="assignRolesFromRule"
        :model="assignRolesFrom"
        @keyup.enter.native="assignRoles()"
        label-width="80px"
      >
        <el-form-item label="所属角色" prop="roleIdList">
          <el-select
            multiple
            v-model="assignRolesFrom.roleIdList"
            placeholder="请选择角色"
            style="width: 100%"
          >
            <el-option
              v-for="item in roleAllList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="assignRolesVisible = false">取 消</el-button>
        <el-button type="primary" @click="assignRoles()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddOrEdit from "./user-add-or-edit";
export default {
  components: {
    AddOrEdit,
  },
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 10,
        current: 1,
      },
      assignRolesFrom: {},
      assignRolesFromRule: {
        roleIdList: [
          { required: true, message: "该项为必选项", trigger: "change" },
        ],
        userId: [{ required: true, message: "该项为必填项", trigger: "blur" }],
      },
      assignRolesVisible: false,
      roleAllList: [],
    };
  },
  created() {
    this.getDataList();
    this.getRoleAllList();
  },
  methods: {
    /**
     * 获取所有角色
     *  */
    getRoleAllList() {
      this.$http.get(`admin/sys-role/list`).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data) this.roleAllList = data;
        }
      });
    },
    /**
     * 给用户分配角色
     */
    assignRolesPopup(userId, roleIdList) {
      this.assignRolesVisible = true;
      this.assignRolesFrom = {};
      this.assignRolesFrom.userId = userId;
      if (roleIdList) this.$set(this.assignRolesFrom, "roleIdList", roleIdList);
    },
    assignRoles() {
      this.$refs["assignRolesFrom"].validate((valid) => {
        if (valid) {
          this.$http
            .post(
              `admin/sys-user-role/saveOrUpdateSysUserRole`,
              this.assignRolesFrom
            )
            .then((res) => {
              if (res.data.code == 200) {
                this.getDataList();
                this.assignRolesVisible = false;
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
        }
      });
    },
    /**
     * 新增或修改弹窗
     */
    addOrEditPopup(id) {
      this.$nextTick(() => {
        this.$refs.addOrEdit.init(id);
      });
    },
    /**
     * 删除数据
     */
    del(id) {
      if (id) {
        this.$confirm("是否删除所选择的数据?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http
              .delete(`admin/sys-user/baseDeleteById/${id}`)
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.queryData.current = 1;
      this.loading = true;
      this.$http
        .get(`admin/sys-user/getSelectPage`, {
          params: this.queryData,
        })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            }
            this.loading = false;
          }
        });
    },
    getDataListSizeChange(val) {
      this.queryData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.queryData.current = val;
      this.getDataList();
    },
  },
};
</script>
